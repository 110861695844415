import { gsap } from "gsap";
import Draggable from "gsap/Draggable";
import { animation } from "./anim.js";
import { doc } from "prettier";

gsap.registerPlugin(Draggable);

const blob = document.getElementById("blob");
const baseScale = 3; // Initial scale value for the blob
const scaleDur = 0.35; // Duration of the scale animations

gsap.set("#blob", {
  x: 0,
  y: 0,
  transformOrigin: "center",
});

// Draggable animation for the blob
Draggable.create("#blob", {
  type: "x,y",
  onDrag: function () {
    gsap.to("#blob", {
      scale: baseScale - 1, // Scale down the blob while dragging
      ease: "power2.Out",
      duration: 0.7,
    });
    let speed = 2;
    if (animation) {
      animation.setSpeed(speed);
    }
  },
  onDragEnd: function () {
    gsap.to("#blob", {
      x: 0,
      y: 0,
      duration: 1.3, // Move the blob back to its initial position after dragging ends
      ease: "elastic.out(.8,0.22)", // Apply elastic easing to the movement
      scale: baseScale,
    });
  },
});

let isTouchDevice = "ontouchstart" in document.documentElement;
if (!isTouchDevice) {
  // Scale up the blob on mouse enter
  blob.addEventListener("mouseenter", function () {
    gsap.to("#blob", {
      scale: baseScale + 1, // Scale up the blob on mouse enter
      duration: scaleDur,
    });
  });

  // Scale down the blob on mouse leave
  blob.addEventListener("mouseleave", function () {
    gsap.to("#blob", {
      scale: baseScale, // Scale down the blob on mouse leave
      duration: scaleDur,
    });
  });
}

// Wrap each letter in a span
function wrapLetters() {
  const title = document.getElementById("title");
  const text = title.innerText;
  title.innerHTML = text
    .split("")
    .map((char) => `<span>${char}</span>`)
    .join("");
  title.classList.remove("opacity-0");
}
// Call the function to wrap letters
wrapLetters();

gsap.to("#title span", {
  delay: 0.3,
  opacity: 1,
  y: 0,
  stagger: 0.04, // Stagger the animation by 0.1 seconds for each letter
  ease: "expo.out",
  duration: 1,
  onComplete: setupHoverEffects,
});

// Function to set up hover effects after initial animation is complete
function setupHoverEffects() {
  const spans = document.querySelectorAll("#title span");

  spans.forEach((span, index) => {
    span.addEventListener("mouseenter", () => {
      // Animate hovered letter up by 10px
      gsap.to(span, { y: -15, duration: 0.3, ease: "power2.out" });

      // Animate previous and next letters up by 5px
      if (index > 0) {
        gsap.to(spans[index - 1], { y: -7, duration: 0.3, ease: "power2.out" });
      }
      if (index < spans.length - 1) {
        gsap.to(spans[index + 1], { y: -5, duration: 0.3, ease: "power2.out" });
      }
    });

    span.addEventListener("mouseleave", () => {
      // Reset all letters to their original position
      gsap.to(spans, { y: 0, duration: 0.3, ease: "power2.out" });
    });
  });
}

gsap.to("#subtitle", {
  delay: 0.8,
  opacity: 0.5,
  y: 0,
  ease: "sine.out",
  duration: 2,
});

// Select the elements
const subtitle = document.getElementById("subtitle");
const leftBracket = document.getElementById("left-bracket");
const rightBracket = document.getElementById("right-bracket");

const brackShiftx = -5;
const brackShifty = -1;
const brackScale = 1.3;
const brackEase = "power2.out";
const brackDur = 0.3;

// Create the hover animation
subtitle.addEventListener("mouseenter", () => {
  gsap.to(leftBracket, {
    x: -brackShiftx + 7,
    y: brackShifty,
    scale: brackScale,
    duration: brackDur,
    ease: brackEase,
  });
  gsap.to(rightBracket, {
    x: brackShiftx,
    y: brackShifty,
    scale: brackScale,
    duration: brackDur,
    ease: brackEase,
  });
});

subtitle.addEventListener("mouseleave", () => {
  gsap.to(leftBracket, {
    x: 0,
    y: 0,
    scale: 1,
    duration: brackDur,
    ease: brackEase,
  });
  gsap.to(rightBracket, {
    x: 0,
    y: 0,
    scale: 1,
    duration: brackDur,
    ease: brackEase,
  });
});
